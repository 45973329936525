import React from 'react';
import { Buffer } from 'buffer';
import { isArray } from 'util';
import { submitSolrQuery, filterToString, fieldList, FacetData } from './Query.js';
import { MyBin } from './Trashcan.js';
import { checkAcl } from '../../adminressources/ACLHelper.js';
import { Field_Info, config as getConfig } from './Config.js';

import { Tag, Tooltip, Whisper, toaster, Message } from 'rsuite';
import Cookies from 'universal-cookie';

export const cookies = new Cookies();
// Sets description limit
export const desc_limit = 1000;

// Used by bbox to check if arary set is equal
export const setsEqual = (a, b) => a.length === b.size && a.every(value => b.has(value));

// checks if value and input match
const matches = (value_match, input) => {

    let value = (input).field !== undefined ? (input).field.value : input;

    if (isArray(value)) {
        return value.some(val => matches(value_match, val));
    } else {

        let val = value + "";

        if (value_match.value !== undefined && value_match.value !== null) {
            return val === value_match.value;
        }

        if (value_match.regex !== undefined && value_match.regex !== null) {

            let regex = new RegExp(value_match.regex.expression);

            return regex.test(val) === value_match.regex.requires_match;
        }

        return true;

    }


}

// Method called to get the token from the local storage
export const getToken = () => {
    let token = localStorage.getItem("T");
    if (token !== undefined && token !== null) {
        return decrypt_storage_values(token);
    }
    return token;
}

// Method called to get the token from the local storage
export const getExpires = () => {
    let expires = cookies.get('E')
    if (expires !== undefined && expires !== null) {
        return JSON.parse(decrypt_storage_values(expires))
    }
    return "";
}

// Method called to get the view from the session storage
export const getView = () => {
    let view = JSON.parse(localStorage.getItem("V"));
    return view;
}

// Method to get the login details
export const getLogin = () => {
    let login = localStorage.getItem("L");
    if (login !== undefined && login !== null) {
        return JSON.parse(decrypt_storage_values(login));
    }
    return login;
}

export const getLoginValid = () => {
    let login = getLogin();
    return login.is_active;
}

export const checkReindex = async () => {
    let token = getToken();
    // Api call to return the dataimpoart check.
    return await fetch("../admin/api/v1/solr/dataimport", {
        method: "GET",
        headers: { "Semabench-Token": token }
    }).then(res => {
        return res.json()
    })
        .then(response => {
            if (response.status === "busy" && response.statusMessages["Total Rows Fetched"] !== undefined && response.statusMessages["Time Elapsed"] !== undefined && response.statusMessages["Full Dump Started"] !== undefined) {
                toaster.push(
                    <Message type="info" showIcon closable>SchoolBench is currently running a reindex process. Your requested change cannot be made until this process is completed.</Message>,
                    { duration: 10000 }
                );
                return true
            } else {
                return false
            }
        })
}

export const conditionMatches = (create_mode, conditions, fields) => {

    if (conditions.create_mode !== undefined && conditions.create_mode !== null) {
        if (create_mode !== conditions.create_mode) {
            return false;
        }
    }

    if (conditions.edit_mode !== undefined && conditions.edit_mode !== null) {
        if (create_mode === conditions.edit_mode) {
            return false;
        }
    }

    let change_fields = Object.keys(fields).filter(field => {

        let val = fields[field];

        if (val !== undefined && val !== null) {
            if ((val).field !== undefined && (val).field !== null) {
                val = (val).field.value;
            }
            if (isArray(val)) {
                return val.some(val => (val + "").length > 0)
            } else {
                return (val + "").length > 0
            }
        }

        return false;
    });

    if (conditions.required_fields !== undefined && conditions.required_fields !== null) {
        let has_missing_fields = conditions.required_fields.some(field => change_fields.indexOf(field) === -1);

        if (has_missing_fields) {
            return false;
        }
    }

    if (conditions.absent_fields !== undefined && conditions.absent_fields !== null) {

        let has_fields = conditions.absent_fields.some(field => change_fields.indexOf(field) !== -1);

        if (has_fields) {
            return false;
        }
    }

    if (conditions.matched_values !== undefined && conditions.matched_values !== null) {
        let matched_values = conditions.matched_values;

        let has_different_value = Object.keys(matched_values).some(field => {

            if (fields[field] === undefined || fields[field] === null) {
                return true;
            }

            let val = fields[field];

            return !matches(matched_values[field], val);

        });

        if (has_different_value) {
            return false;
        }
    }

    if (conditions.unmatched_values !== undefined && conditions.unmatched_values !== null) {
        let unmatched_values = conditions.unmatched_values;
        let has_same_value = Object.keys(unmatched_values).some(field => {
            if (fields[field] !== undefined && fields[field] !== null) {
                let val = fields[field];

                return matches(unmatched_values[field], val);
            }

            return false;
        })

        if (has_same_value) {
            return false;
        }
    }


    return true;
}


const getPathNodes = (isActive, query, config) => {
    if (query.facets["path_starts_with"]) {
        let raw_paths = query.facets["path_starts_with"];
        let path_keys = Object.keys(raw_paths);

        // used to clean any unwanted characters that might be placed.
        const cleanupPath = (path) => {
            let output_path = path.replace(config.path_strip, "").split("/");
            return output_path;
        }

        var output = [];
        for (var i = 0; i < path_keys.length; i++) {
            if (path_keys[i].startsWith(config.path_strip)) {
                var chain = cleanupPath(path_keys[i]);
                var current_node = output;

                for (var j = 0; j < chain.length; j++) {
                    var wanted_node = chain[j];

                    var last_node = current_node;

                    for (var k = 0; k < current_node.length; k++) {
                        if (current_node[k].label === wanted_node) {
                            current_node = current_node[k].childNodes;
                            break;
                        }
                    }

                    // If we couldn't find an item in this list of children
                    // that has the right name, create one:
                    if (last_node === current_node) {
                        var lastChain = j === chain.length - 1;

                        let id = "path_" + chain.slice(0, j + 1).join("/");
                        let value = config.path_strip + chain.slice(0, j + 1).join("/");
                        let secondaryLabel = <span />;
                        let isSelected = false;

                        if (isActive && config.view.filters.findIndex(filter => filter.field_name === "path_start_with" && filter.value.some(val => val === value))) {
                            isSelected = true;
                        }

                        if (lastChain && raw_paths[path_keys[i]]) {
                            secondaryLabel = <span>{isSelected ? raw_paths[path_keys[j]] : raw_paths[path_keys[i]]}</span>;
                        }

                        var new_node = current_node[k] = {
                            id: id,
                            type: "value",
                            inverse: false,
                            field_name: "path_starts_with",
                            icon: "",
                            label: wanted_node,
                            secondaryLabel: secondaryLabel,
                            isExpanded: false,
                            value: value,
                            isSelected: isSelected,
                            childNodes: []
                        };

                        current_node = new_node.childNodes;
                    }
                }

                const removeCaret = (node) => {
                    if (node.childNodes && node.childNodes.length > 0) {
                        node.hasCaret = true;
                        node.childNodes.forEach((node) => removeCaret(node));
                    } else {
                        node.icon = node.icon === "folder-open" ? "folder-close" : node.icon;
                        node.hasCaret = false;
                    }
                }
                output.forEach((node) => removeCaret(node))
                return output;
            } else {
                return [];
            }
        }
    }
}

export const getAlbumUid = (node) => {
    let token = getToken();
    let header = {
        'Semabench-Token': token,
        'Content-Type': 'application/json'
    }

    return fetch("../api/v1/album/user?node=" + node, {
        headers: header
    }).then(resp => resp.json())
        .then(result => {
            return result;
        })
}

export const clearFilter = async () => {
    let view = getView();
    view.filters = [];
    localStorage.setItem("V", JSON.stringify(view));
    await submitSolrQuery("clean-query").then(() => {
        return;
    });
}


// Loads bin data and compares
export const getBinDoc = (docs) => {
    let trashcan = MyBin

    let new_docs = [];

    if (trashcan !== undefined && trashcan !== null) {
        docs.forEach(doc => {
            if (trashcan.includes(doc.id)) {
                new_docs.push(doc)
            }
        })
    }

    return new_docs;

}

export const getNodes = (addedNode) => {
    // loads the config and default system settings from local storage
    let view = getView();
    let facets = view.facets;

    if (addedNode !== undefined && addedNode !== null) {
        facets.push(addedNode);
    }

    let query = FacetData;
    if (query === undefined || query === null) {
        query = []
    }

    if (query !== undefined && query !== null) {

        let facet_response = query;
        if (facet_response === undefined || facet_response === null) {
            return [];
        } else {
            let info = Field_Info;

            let nodes = facets.filter((facet) => facet_response[facet] !== null && facet_response[facet] !== undefined).map((facet, index) => {


                // creates the values that will be used by looking into each node and the values set.
                let isActive = view.filters.some(filter => filter.field_name === facet);

                let facet_response = query[facet];

                let entries = facet_response;

                let children = [];

                if (facet === "path_starts_with") {
                    children = getPathNodes(isActive); //TODO need to check this
                } else {
                    let sorted_terms = entries.map(bucket => bucket.val);

                    let is_list = false;

                    /*
                        Sort based upon the field definition
                    */

                    if (info.fields[facet] !== undefined && info.fields[facet] !== null) {
                        let field_info = info.fields[facet];

                        // Gets mandatory fields that are required to be displayed. e.g Terms
                        if (field_info.constraints != null) {
                            let constraints = info.fields[facet].constraints;
                            if (constraints !== undefined && constraints.list !== undefined && constraints !== null && constraints.list !== null) { // need to check
                                sorted_terms = constraints.list;
                                is_list = true;
                            }
                        }
                    }

                    // Add in any applied files as per the filer view if it's not listed (shows up with no count)
                    if (isActive) {
                        view.filters.filter(filter => filter.field_name === facet)
                            .forEach(filter => {
                                filter.value.forEach(val => {
                                    if (sorted_terms.indexOf(val) === -1) {
                                        sorted_terms.push(val);
                                    }
                                })
                            });
                    }

                    // Tree Facet Query is going to be null
                    let facet_query = false;
                    if (facet_query === true) {

                    } else {
                        // Loops through each facet to find the children nodes. E.g finds years for sb_year (2020,2021)
                        if (sorted_terms !== null && sorted_terms !== undefined) {

                            children = sorted_terms.map((child, childIndex) => {
                                // Gets all the additional info like the count from the inner node
                                let bucket = entries.find(bucket => bucket.val === child);

                                let count = bucket !== undefined ? bucket.count : 0;

                                let secondaryLabel = bucket !== undefined || is_list ? <span>{count} </span> : undefined;
                                // let icon = "";
                                let isSelected = false;
                                let inverse = false;
                                let filter_rule = undefined;
                                // let isHighlighted = undefined;

                                let filter = undefined;

                                let active_filter_rule = view.filter_rules || [];

                                let rule = Object.entries(view.filter_rules).filter(val => active_filter_rule.some(rule => rule === val[0])).find(val => val[1].field_name === facet && val[1].value.some(val => val === child));

                                if (rule !== undefined && rule !== null) {
                                    filter_rule = rule[0];
                                    filter = rule[1];
                                }

                                if (filter === undefined || filter === null) {
                                    filter = view.filters.find(filter => filter.field_name === facet && filter.value.some(val => val === child));
                                }

                                if (filter !== undefined && filter !== null) {
                                    inverse = filter.inverse;
                                    isSelected = true;
                                }

                                let label_value = info.lookup[child] || child;

                                let label;

                                //tool tip wording for grid size
                                let tooltipAddToAlbum = <Tooltip>
                                    {label_value}
                                </Tooltip>

                                if (facet === "sb_event" || facet === "sb_tags" || facet === "sb_class" || facet === "sb_student" || facet === "sb_staff" || facet === "sb_visitor" || facet === "sb_pastStudent") {
                                    label = <Whisper placement='bottom' trigger="hover" speaker={tooltipAddToAlbum}>
                                        <span className="d-flex flex-row wbr200">

                                            <span>{label_value}</span><Tag className="float-right text-xxsmall mt-0 ml-2 pt-0 pb-0" color="blue">{count}</Tag>

                                        </span>
                                    </Whisper>
                                } else {
                                    label = <span className="d-flex flex-row wbr200"><span>{label_value}</span><Tag className="float-right text-xxsmall mt-0 ml-2 pt-0 pb-0" color="blue">{count}</Tag></span>
                                }

                                // If controlled click or invers a "-" will appear next to it. 
                                if (inverse) {
                                    if (facet === "sb_event" || facet === "sb_tags" || facet === "sb_class" || facet === "sb_student" || facet === "sb_staff" || facet === "sb_visitor" || facet === "sb_pastStudent") {
                                        label = <Whisper placement='bottom' trigger="hover" speaker={tooltipAddToAlbum}><span className="d-flex flex-row wbr200"><span title={label_value}>- {label_value}</span><Tag className="float-right text-xxsmall mt-0 ml-2 pt-0 pb-0" color="blue">{count}</Tag></span></Whisper>
                                    } else {
                                        label = <span className="d-flex flex-row wbr200"><span>- {label_value}</span><Tag className="float-right text-xxsmall mt-0 ml-2 pt-0 pb-0" color="blue">{count}</Tag></span>
                                    }
                                }

                                let readableName = info.lookup[facet] || facet;

                                return {
                                    id: facet + childIndex,
                                    type: "value",
                                    inverse,
                                    labelExtra: label,
                                    label: label_value,
                                    filter_rule,
                                    className: "",
                                    icon: "",
                                    secondaryLabel: secondaryLabel,
                                    isSelected: isSelected,
                                    field_name: facet,
                                    value: child,
                                    readableName
                                }

                            });
                        }
                    }
                }

                let isExpanded = false;
                let isSelected = isExpanded === false ? isActive : false;

                // returns a child. with the value and what field_name it belongs too.
                return {
                    id: facet,
                    hasCaret: true,
                    isExpanded: isExpanded,
                    isSelected: isSelected,
                    label: facet,
                    field_name: facet,
                    icon: "",
                    childNodes: children
                }

            });


            return nodes
        }
    } else {
        return [];
    }

}

// Gets the src of the image
export const getSrc = (props, type) => {

    let solrVersion = props.cm_modified || "0"


    let preview_kinds = props.preview_kind;
    // let preview_ref = props.preview_ref;

    let kind = undefined;
    // let ref = undefined; //ref is just to update if there is a change to the rendition



    let token = getToken();

    if (type === "original") {
        if (preview_kinds !== undefined && preview_kinds !== null) {
            if (!preview_kinds.includes("pdfthumb") && !preview_kinds.includes("xthumb") && !preview_kinds.includes("heifbbox") && !preview_kinds.includes("rawbbox")) {
                kind = type;
            }
        } else {
            kind = type;
        }
    }

    if (preview_kinds !== undefined && preview_kinds !== null) {
        if (kind !== "original") {
            let idx = preview_kinds.findIndex(kind => kind === "doclib" || kind.indexOf("thumb") >= 0);
            if (type === "original") {
                idx = preview_kinds.findIndex(kind => kind === "doclib" || kind.indexOf("bbox") >= 0);
            }

            if (idx !== -1) {
                kind = preview_kinds[idx];
            }
        }
    }

    if (type !== "original" && type !== "betterthumb" && type !== undefined && type !== null) {
        kind = type
    }

    let token_encode = encodeURIComponent(token);
    let src = undefined;

    if (kind === undefined || kind === null) {
        kind = "original";
    }
    src = `../api/v1/repo/s/semabench/preview?id=${props.id}&semabench-token=${token_encode}&kind=${kind}&version=${solrVersion}`;

    return src;

}

// const algorithm = "aes-256-cbc";

// generate 16 bytes of random data
const initVector = Buffer("initVector comon");

// secret key generate 32 bytes of random data
const Securitykey = "This needs to be a long password";

export const encrypt = (value) => {

    const crypto = require("crypto-js");

    let ciphertext = crypto.AES.encrypt(value, crypto.enc.Utf8.parse(Securitykey), {
        iv: crypto.enc.Utf8.parse(initVector), // parse the IV 
        padding: crypto.pad.Pkcs7,
        mode: crypto.mode.CBC
    }).toString();

    return ciphertext;
}

export const decrypt = (value) => {

    const crypto = require("crypto-js");

    let cipher = crypto.AES.decrypt(value, crypto.enc.Utf8.parse(Securitykey), {
        iv: crypto.enc.Utf8.parse(initVector), // parse the IV ,
        padding: crypto.pad.Pkcs7,
        mode: crypto.mode.CBC
    });

    return cipher.toString(crypto.enc.Utf8);
}

export const loadProfiles = async (val) => {
    // Gets the users local storage token
    let token = getToken();
    let config = getConfig;
    let view = getView()
    let headers = { 'Content-Type': 'application/json', "Semabench-Token": token, "Cache-Control": "max-age=0" };

    // Checks if a value has been parsed, if not its a blank string
    if (val === undefined || val === null || val === "") {
        val = "*:*"
    };

    // Gets the default filters from the config settings
    let filters = [config.default_filter];

    // Loads the info (facet) from local storeage
    let info = Field_Info;

    // Adds filters, as we are only looking for profiles
    view.filters = [{ field_name: "sb_adminStatus", value: ["Profile"], inverse: false }];

    // Adds the custom filters into the filters variable
    filters = filters.concat(view.filters
        .filter(filter => filter.field_name !== "Advanced")
        .map(filter => filterToString(filter, info.fields)));

    // Creates the json for the filters to send in the body of the fetch command
    let body = JSON.stringify({
        filter: filters
    })

    // Sets fl (filed list) to the default field list that we need per Node
    let fl = encodeURIComponent(fieldList(info.fields).join(","));

    // Sets the names that we will be searching on for the suggestive search
    let qf = `sb_profileName_q^2 sb_otherNames_q`

    // Calls the query function to get the results
    return await fetch(`../api/v1/repo/s/semabench/query?q=${val.toLowerCase()}&wt=json&json.nl=map&q.op=AND&defType=edismax&sow=false&qf=${qf}&pf=${qf}&fl=${fl}`, {
        method: 'POST',
        headers: headers,
        body: body
    }).then(resp => resp.json())
        .then(response => {

            // If there is a response we step into
            if (response !== undefined && response !== null) {
                let result = response.response;
                let profiles = [];

                // Pushes "Unknown" into the filter if nothing is applied
                profiles.push({ label: "Unknown", value: "", type: undefined, othername: "Unknown Uknown" })
                if (result !== undefined && result !== null) {
                    // If there are docs from the response we step into
                    if (result.docs !== undefined && result.docs !== null) {
                        // For each response we create the drop down element
                        result.docs.forEach(doc => {
                            let data = {
                                label: doc.sb_profileName,
                                value: doc.id,
                                type: doc.sb_profileType,
                            }
                            profiles.push(data);
                            // Adds an extra placeholder object in the drop down for other name
                            if (doc.sb_otherNames !== undefined && doc.sb_otherNames !== null) {
                                let data = {
                                    label: doc.sb_otherNames[0] + " (Other name)",
                                    value: doc.id,
                                    type: doc.sb_profileType,
                                }
                                profiles.push(data);
                            }

                        })
                    }
                }
                // Sets the list of profiles
                return profiles
            }

        })
}

// Method to check if items already encrypted
export const checkDecrypt = (value) => {
    let check_decryption = true

    try {
        decrypt(value);
    } catch {
        check_decryption = false;
    }

    return check_decryption;
}

//encrypt the values on storages/cookies
export const encrypt_storage_values = (value) => {
    if (value !== undefined && value !== null) {
        const crypto = require("crypto-js");

        let ciphertext = crypto.AES.encrypt(value, crypto.enc.Utf8.parse(Securitykey), {
            iv: crypto.enc.Utf8.parse(initVector), // parse the IV 
            padding: crypto.pad.Pkcs7,
            mode: crypto.mode.CBC
        }).toString();

        return ciphertext;
    } else {
        return null
    }
}

//decrypt the values on storages/cookies
export const decrypt_storage_values = (value) => {
    if (value !== undefined && value !== null) {
        const crypto = require("crypto-js");

        let cipher = crypto.AES.decrypt(value, crypto.enc.Utf8.parse(Securitykey), {
            iv: crypto.enc.Utf8.parse(initVector), // parse the IV ,
            padding: crypto.pad.Pkcs7,
            mode: crypto.mode.CBC
        });

        return cipher.toString(crypto.enc.Utf8);
    } else {
        return null
    }
}

//check the current login token
export const Check_login_ticket = async () => {

    let token = getToken();
    let expires = getExpires();

    await fetch("../api/v1/repo/s/semabench/ticket", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Semabench-Token': token,
            'source': 'media'
        }
    }).then(async res => {
        if (res.status === 401) {
            if (new Date() < new Date(expires)) {
                await refresh_token()
            } else {
                await redirect_to_login()
            }
        }
    })
}

//check the current login token on the admin pages
export const Check_login_ticket_admin = async (page, status) => {

    let token = getToken();
    let expires = getExpires();

    let message = 'You cannot '

    if (status === "save" || status === "test") {
        if (page === "vpn") {
            message = 'You cannot enable the Remote Access'
        } else if (page === "password") {
            message += 'disable the default Admin Passowrd'
        } else if (page === "ldap") {
            message += status + " LDAP Configuration"
        } else if (page === "sbsms") {
            message += status + " SIS Integration"
        } else if (page === "schoolbench") {
            message += status + " Calendar Integration"
        } else if (page === "webdav") {
            message += status + " WebDAV Setting"
        } else if (page === "acls") {
            message += status + " User Permissions"
        } else if (page === "media") {
            message += status + " Media Consent Mapping"
        } else if (page === "renditions") {
            message += status + " Rensition Settings"
        } else if (page === "facenet") {
            message += status + " Facial Recognition Settings"
        } else if (page === "pportal") {
            message += status + " Portal Settings"
        } else if (page === "new_pportal") {
            message += status + " Portal+ Settings"
        } else if (page === "mobile") {
            message += status + " Mobile Settings"
        } else if (page === "impersonate") {
            message = "You cannot impersonate the user"
        } else if (page === "portal logo") {
            message += status + " Portal+ Logo"
        } else if (page === "portal banner") {
            message += status + " Portal+ Banner"
        } else if (page === "versioncheck") {
            message = "You cannot check the latest version"
        } else {
            message += status + " on " + page
        }
    } else {
        // load
        message += status + " the changes on " + page

    }

    message += " as your session has expired."

    await fetch("../api/v1/repo/s/semabench/ticket", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Semabench-Token': token,
            'source': 'media'
        }
    }).then(async res => {
        if (res.status === 401) {
            toaster.push(
                <Message type="error" showIcon closable>{message}</Message>,
                { duration: 10000 }
            );
            if (new Date() < new Date(expires)) {
                await refresh_token()
            } else {
                await redirect_to_login()
            }
        }

    })
}

//regenerate the login token and cookies
const refresh_token = async () => {
    let login = getLogin();
    let expires = getExpires();
    let config = getConfig;

    toaster.push(
        <Message type="warning" showIcon closable>You session has expired. Please wait while a new session is generated.</Message>,
        { duration: 10000 }
    );
    await setTimeout(async () => {
        localStorage.removeItem("L");
        localStorage.removeItem("T");
        cookies.remove("T", { path: "/" });
        cookies.remove("E", { path: "/" });

        await fetch("../api/v1/repo/s/semabench/new_ticket", {
            method: "POST",
            mode: 'no-cors',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Source": 'media'
            },
            body: JSON.stringify({ name: login.username, value: "", source: 'media' })
        }).then(res => res.json())
            .then(async data => {
                if (data !== undefined && data !== null) {

                    localStorage.setItem("L", encrypt_storage_values(JSON.stringify(data)));
                    localStorage.setItem("T", encrypt_storage_values(data.ticket));
                    cookies.set('T', encrypt_storage_values(data.ticket), { path: '/', expires: new Date(expires), secure: true, sameSite: "Strict" });
                    cookies.set('E', encrypt_storage_values(JSON.stringify(new Date(expires))), { path: '/', expires: new Date(expires), secure: true, sameSite: "Strict" });

                    if (checkAcl(data.roles, config.acls, "VIEW_PROPERTIES") || checkAcl(data.roles, config.acls, "ADMIN")) {
                        window.location.replace(document.URL);
                    }
                } else {
                    toaster.push(
                        <Message type="error" showIcon closable>You do not have View Permissions Now, please contact your IT support</Message>
                    );
                    // Maybe show alert as to no view permissions?
                }
                return data;
            })
    }, 10000)

}

//redirect to the login page as the current login token is too old, and can not regenerate it.
const redirect_to_login = async () => {
    toaster.push(
        <Message type="error" showIcon closable>Your session has expired. You are now being redirected to log in again in 10 sec</Message>,
        { duration: 10000 }
    );
    await setTimeout(() => {

        cookies.remove("T", { path: "/" });
        cookies.remove("E", { path: "/" });
        sessionStorage.clear();
        let theme = localStorage.getItem("theme");
        localStorage.clear();
        if (theme !== null && theme !== undefined) {
            localStorage.setItem("theme", theme)
        }
        window.location.replace("../login");
    }, 10000)
}
