import React from 'react';
import { Buffer } from 'buffer';
import LegacyCheckIcon from "@rsuite/icons/legacy/CheckSquareO";
import LegacyCloseIcon from "@rsuite/icons/legacy/WindowCloseO";
import { loadConfig } from '../../ressources/functions/Config';
import { MASTER_TOKEN } from '../portalplus/PortalHelper';
import { getToken, encrypt, decrypt, Check_login_ticket_admin } from '../../ressources/functions/helper';

const token = getToken();
export var pillars = undefined;

export const getPillar = async () => {
    return await loadPillar().then(resp => {
        pillars = resp
        return resp
    })
}

// Loads the pillar from the admin panel. Only works if admin
export const loadPillar = () => {
    let headers = { "Semabench-Token": MASTER_TOKEN }
    return fetch("../admin/api/v1/pillar", {
        headers: headers
    }).then(resp => resp.json())
        .then(result => {
            return result;
        })
}

// Method to check if items already encrypted
const checkEncryptions = (value) => {
    let do_encryption = false

    try {
        if (decrypt(value) === "") {
            do_encryption = true;
        }
    } catch {
        do_encryption = true;
    }
    return do_encryption;
}

export const testPillar = async (section, data) => {
    let payload = data

    let headers = payload instanceof FormData ? {} : {}

    if (section !== "sslproxy") {
        headers["Content-Type"] = 'application/json';
    }

    headers["Semabench-Token"] = token;

    let url = "../admin/api/v1/" + section + "/test"

    //This if condition is used to change section from 'schoolbench' / sbsms to 'integrations'
    //As ical and sis integration pages used to use 'integrations' name to call the api.
    if (section === "schoolbench") {
        url = "../admin/api/v1/calendar/test"
    }
    else if (section === "sbsms") {
        url = "../admin/api/v1/integrations/test"

        // Checks if the password is encrypted
        if (checkEncryptions(payload.password)) {
            payload.password = encrypt(payload.password);
        }

        // Checks if the token is encrypted
        if (payload.token !== undefined) {
            if (checkEncryptions(payload.token)) {
                payload.token = encrypt(payload.token);
            }
        }

        // Checks if the token is encrypted
        if (payload.token_staff !== undefined) {
            if (checkEncryptions(payload.token_staff)) {
                payload.token_staff = encrypt(payload.token_staff);
            }
        }

        payload = { "sbsms": payload };
    }
    else if (section === "sslproxy") {

        // Handling the encryption of SSO Password
        let password = payload.password

        // Payload for the form data field for SSO
        let newpayload = new FormData();
        newpayload.append("hostname", payload.hostname)
        newpayload.append("certificate", payload.certificate)
        newpayload.append("password", password)
        newpayload.append("sso_type", payload.sso_type)
        newpayload.append("krb_realm", payload.krb_realm)
        newpayload.append("krb_kdc", payload.krb_kdc)
        newpayload.append("krb_keytab", payload.krb_keytab)
        newpayload.append("oidc_metadata_url", payload.oidc_metadata_url)
        newpayload.append("oidc_client_id", payload.oidc_client_id)
        newpayload.append("oidc_secret", payload.oidc_secret)
        newpayload.append("saml_metadata_url", payload.saml_metadata_url)
        newpayload.append("saml_login_url", payload.saml_login_url)
        newpayload.append("saml_cert", payload.saml_cert)
        newpayload.append("saml_uid_name", payload.saml_uid_name)
        newpayload.append("saml_group_name", payload.saml_group_name)
        newpayload.append("enable_timeout", payload.enable_timeout)
        newpayload.append("action_timeout", payload.action_timeout)
        newpayload.append("popup_timeout", payload.popup_timeout)

        return await fetch(url, {
            method: "POST",
            headers: headers,
            body: newpayload
        }).then(resp => {
            //redirect the users to the login pages if the login token is invalid
            if (resp.status === 401) {
                Check_login_ticket_admin(section, 'test')
            }
            return resp.json()
        })
            .then(result => {

                let responseEle = testPillarEle(result);
                return responseEle
            })
            .catch(error => {

                return <></>
            })
    } else if (section === "impersonate") {

        url = "../admin/api/v1/" + section + "/" + payload;

        return await fetch(url, {
            method: "GET",
            headers: headers
        })
            .then(resp => {
                //redirect the users to the login pages if the login token is invalid
                if (resp.status === 401) {
                    Check_login_ticket_admin(section, 'test')
                }
                return resp.json()
            })
            .then(result => {
                return result
            })
    }
    else if (section === "versioncheck") {
        url = "../admin/api/v1/version/check";
    }
    else if (section === "listupdate") {
        url = "../admin/api/v1/system/listupdate"
    } else if (section === "ldap") {

        for (let i = 0; i < payload.length; i++) {
            if (checkEncryptions(payload[i].credentials)) {
                payload[i].credentials = encrypt(payload[i].credentials);
            }

            // Adds encryption for test users
            if (payload[i].test !== undefined) {
                if (checkEncryptions(payload[i].test.pass)) {
                    payload[i].test.pass = encrypt(payload[i].test.pass);
                }
            }
        }
    } else if (section === "pportal") {
        // Checks and encrypts the auth secret while transfering over the network
        if (payload.auth !== undefined && payload.auth !== null) {
            if (payload.auth.type !== "schoolbox" && payload.auth.secret !== undefined && payload.auth.secret !== null) {
                if (checkEncryptions(payload.auth.secret)) {
                    payload.auth.secret = encrypt(payload.auth.secret);
                }
            }
        }

        if (payload.payment_gateway !== undefined && payload.payment_gateway !== null) {
            if (checkEncryptions(payload.payment_gateway.private_key)) {
                payload.payment_gateway.private_key = encrypt(payload.payment_gateway.private_key);
            }
        }

        if (payload.paypal_gateway !== undefined && payload.paypal_gateway !== null) {
            if (checkEncryptions(payload.paypal_gateway.secret)) {
                payload.paypal_gateway.secret = encrypt(payload.paypal_gateway.secret);
            }
        }
    }

    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload)
    }).then(resp => {
        //redirect the users to the login pages if the login token is invalid
        if (resp.status === 401) {
            Check_login_ticket_admin(section, 'test')
        }
        return resp.json()
    })
        .then(result => {

            if (section === "acls") { //
                // This is to display acl testing result as acl api is returning the different test result format  
                let responseEle = testAclsEle(result);
                return responseEle
            }
            else if (section === "subscription" || section === "portal_subscription") {
                // This is to diplay subcrtipion testing result as it is returning the different test result format
                let responseEle = testtokenEle(result);
                return responseEle
            }
            else if (section === "versioncheck") {
                return result
            }
            else {
                // Normal testing cases
                let responseEle = testPillarEle(result);
                return responseEle
            }

        })
        .catch(result => {
            // This is to diplay failed subcrtipion testing result as it is returning the different test result format
            if (section === "subscription" || section === "portal_subscription") {
                let responseEle = testtokenEle(result);
                //  
                return responseEle
            }
            return <></>
        })
        .catch(error => {

            return <></>
        })

}

export const savePillar = async (section, payload) => {
    let headers = payload instanceof FormData ? {} : {}

    if (section !== "sslproxy" && section !== "ldapsaml") {
        headers["Content-Type"] = 'application/json';
    }

    headers["Semabench-Token"] = token;

    let url = "../admin/api/v1/" + section + "/save";

    // Need to cehck saving of the portal
    if (section === "pportal") {
        // Checks and encrypts the auth secret while transfering over the network
        if (payload.auth !== undefined && payload.auth !== null) {
            if (payload.auth.type !== "schoolbox" && payload.auth.secret !== undefined && payload.auth.secret !== null) {
                if (checkEncryptions(payload.auth.secret)) {
                    payload.auth.secret = encrypt(payload.auth.secret);
                }
            }
        }

        if (payload.payment_gateway !== undefined && payload.payment_gateway !== null) {
            if (checkEncryptions(payload.payment_gateway.private_key)) {
                payload.payment_gateway.private_key = encrypt(payload.payment_gateway.private_key);
            }
        }

        if (payload.paypal_gateway !== undefined && payload.paypal_gateway !== null) {
            if (checkEncryptions(payload.paypal_gateway.secret)) {
                payload.paypal_gateway.secret = encrypt(payload.paypal_gateway.secret);
            }
        }
    }
    else if (section === "sslproxy" || section === "ldapsaml") {

        // Handling the encryption of SSO Password
        let password = payload.password

        // Payload for the form data field for SSO
        let newpayload = new FormData();
        newpayload.append("hostname", payload.hostname)
        newpayload.append("certificate", payload.certificate)
        newpayload.append("password", password)
        newpayload.append("sso_type", payload.sso_type)
        newpayload.append("krb_realm", payload.krb_realm)
        newpayload.append("krb_kdc", payload.krb_kdc)
        newpayload.append("krb_keytab", payload.krb_keytab)
        newpayload.append("oidc_metadata_url", payload.oidc_metadata_url)
        newpayload.append("oidc_client_id", payload.oidc_client_id)
        newpayload.append("oidc_secret", payload.oidc_secret)
        newpayload.append("saml_metadata_url", payload.saml_metadata_url)
        newpayload.append("saml_login_url", payload.saml_login_url)
        newpayload.append("saml_cert", payload.saml_cert)
        newpayload.append("saml_uid_name", payload.saml_uid_name)
        newpayload.append("saml_group_name", payload.saml_group_name)
        newpayload.append("enable_timeout", payload.enable_timeout)
        newpayload.append("action_timeout", payload.action_timeout)
        newpayload.append("popup_timeout", payload.popup_timeout)

        return await fetch(url, {
            method: "POST",
            headers: headers,
            body: newpayload
        }).then(resp => {
            //redirect the users to the login pages if the login token is invalid
            if (resp.status === 401) {
                Check_login_ticket_admin(section, 'save')
            }
            return resp.json()
        })
            .then(result => {
                return result
            })

    }
    else if (section === "acls") { //acl payload need to exclude test user information when acls
        payload = payload.acls
    }
    //This if condition is used to change section from 'schoolbench' / sbsms to 'integrations'
    //As ical and sis integration pages used to use 'integrations' name to call the api.
    else if (section === "schoolbench") {
        url = "../admin/api/v1/calendar/save"
        if (payload.schoolbench !== undefined) {
            payload = payload.schoolbench
        }
    }
    else if (section === "sbsms") {
        url = "../admin/api/v1/integrations/save"

        let found_sbsms = false
        // Checks if payload already contains sbsms object
        Object.keys(payload).forEach(key => {
            if (key === "sbsms") {
                found_sbsms = true;
            }
        })

        if (found_sbsms === false) {
            payload = { "sbsms": payload };
        }
    }
    else if (section === "token") {
        url = ""
    }
    else if (section === "watermark") {
        let newpayload = new FormData();

        newpayload.append("file", payload[0])

        let header = { "Semabench-Token": token };

        return await fetch(url, {
            method: "POST",
            headers: header,
            body: newpayload
        }).then(resp => {
            //redirect the users to the login pages if the login token is invalid
            if (resp.status === 401) {
                Check_login_ticket_admin(section, 'save')
            }
            return resp.json()
        })
            .then(result => {
                return result
            })
    } else if (section === "ldap") {
        for (let i = 0; i < payload.length; i++) {
            if (checkEncryptions(payload[i].credentials)) {
                payload[i].credentials = encrypt(payload[i].credentials);
            }

            // Adds encryption for test users
            if (payload[i].test !== undefined) {
                if (checkEncryptions(payload[i].test.pass)) {
                    payload[i].test.pass = encrypt(payload[i].test.pass);
                }
            }
        }
    } else if (section === "multibanners" || section === "new_pportal") {
        headers["Semabench-Token"] = MASTER_TOKEN;
    }

    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload)
    }).then(resp => {
        //redirect the users to the login pages if the login token is invalid
        if (resp.status === 401) {
            Check_login_ticket_admin(section, 'save')
        }
        return resp.json()
    })
        .then(result => {
            getPillar()
            if (section === "acls") { //
                //acl format is not passed as array so, so calling acl testEls
                return { result }
            }
            else {
                //the array format test results 
                return result
            }
        })
        .catch(error => {

            let result = {
                msg: <>
                    <h1>Error:</h1>
                    <div className="row">
                        <div className="col-12 mb-5">
                            <div className="separator mb-5"></div>
                        </div>
                    </div>
                    <p className="setrequired">Error: {error.toString()}</p>
                </>,
                fail: true
            }
            return (result)
        })
}

export const mediaPillarHelper = (value, pillar) => {
    let new_pillar = pillar;

    Object.keys(value).forEach(k => {
        if (k === "allow_social") {
            new_pillar[k] = value[k];
        }
    })

    return new_pillar;
}

export const renditionPillarHelper = (value, pillar) => {
    let new_pillar = pillar;

    Object.keys(value).forEach(k => {
        if (k === "renditions") {
            new_pillar[k] = value[k];
        }
    })

    return new_pillar;
}

export const admin_pwPillarHelper = (value, pillar) => {
    let new_pillar = pillar;

    Object.keys(value).forEach(k => {
        if (k === "admin_pw") {
            new_pillar[k] = Buffer.from(value[k]).toString('base64');
        }
    })

    return new_pillar;
}


// Handles change for the portal Pillar
export const portalPillarHelper = (value, pillar) => {
    let new_pillar = pillar

    Object.keys(value).forEach(k => {
        if (k === "logo" || k === "auth" || k === "hostname" || k === "primary_colour" || k === "profile_fields"
            || k === "rendition_type" || k === "profile_filters" || k === "photo_filters" || k === "parent_filter"
            || k === "enable_timeout" || k === "enable_quick_parent" || k === "enable_community" || k === "disclaimer"
            || k === "webshop" || k === "payment_gateway" || k === "paypal_gateway" || k === "currency") {
            new_pillar[k] = value[k];
        }
        else if (k === "title" || k === "description") {
            new_pillar.disclaimer[k] = value[k];
        }
        else if (k === "default_price") {
            new_pillar.pricing[k] = parseFloat(value[k]);
            new_pillar[k] = parseFloat(value[k]);
        }
    })

    return new_pillar;
}

// Handles change for the portal Pillar
export const new_portalPillarHelper = (value, pillar) => {
    let new_pillar = pillar

    Object.keys(value).forEach(k => {
        if (k === "logo" || k === "auth" || k === "hostname" || k === "primary_colour"
            || k === "rendition_type" || k === "profile_filters" || k === "photo_filters" || k === "collection_filters"
            || k === "enable_timeout" || k === "disclaimer"
            || k === "webshop" || k === "paypal_gateway" || k === "currency" || k === "banner_url" || k === "enable_banner" || k === "menu" || k === "banner") {
            new_pillar[k] = value[k];
        }
        else if (k === "title" || k === "description") {
            new_pillar.disclaimer[k] = value[k];
        }
        else if (k === "default_price") {
            new_pillar.pricing[k] = parseFloat(value[k]);
            new_pillar[k] = parseFloat(value[k]);
        }
    })

    return new_pillar;
}

// Handles change for the mobile Pillar
export const mobilePillarHelper = (value, pillar) => {
    let new_pillar = pillar

    Object.keys(value).forEach(k => {
        if (k === "delete_after_upload" || k === "privacy_policy") {
            new_pillar[k] = value[k];
        }
        else if (k === "days_before_deletion" || k === "lock_timeout") {
            new_pillar[k] = parseInt(value[k]);
        }
    })

    return new_pillar
}

//Handles change for the facenet Pillar
export const FacenetPillarHelper = (value, pillar) => {
    let new_pillar = pillar

    Object.keys(value).forEach(k => {
        if (k === "reverse_lookup") {
            new_pillar[k] = value[k];
        }
        else if (k === "resize" || k === "num_cpus") {
            new_pillar[k] = parseInt(value[k]);
        } else if (k === "verified_threshold" || k === "upper_threshold" || k === "size_ratio_cutoff"
            || k === "size_ratio_step" || k === "size_ratio_step_amount" || k === "year_step_amount") {
            new_pillar[k] = parseFloat(value[k]);
        }
    })

    return new_pillar
}

//Handles change for the Media Pillar
export const MediaPillarHelper = (value, pillar, index) => {
    let new_pillar = pillar

    Object.keys(value).forEach(k => {
        if (k === "allow_social" || k === "checkbox_names" || k === "media_consent") {
            new_pillar[k] = value[k];
        }
        else if (k === "add_media_consent") {
            new_pillar.media_consent.push({
                field_name: value[k],
                value: []
            });
        }
        else if (k === "add_checkbox") {
            new_pillar.checkbox_names.push(value[k]);
        }
        else if (k === "value") {
            new_pillar.media_consent[index][k] = value[k];
        }
        else if (k === "edit") {
            new_pillar.checkbox_names[index] = value[k]
        }
        else if (k === "delete_checkbox") {
            new_pillar.checkbox_names.splice(index, 1);
        }

    })

    return new_pillar
}


//Handles change for the ldap Pillar  
export const LdapPillarHelper = (type, value, index, pillar) => {

    let new_pillar = pillar

    if (type === "url" || type === "credentials" || type === "principal" || type === "groupSearchBase" || type === "userSearchBase" ||
        type === "personType" || type === "groupType" || type === "userNameFormat" || type === "groupQuery" || type === "personQuery" ||
        type === "userIdAttributeName") {
        new_pillar[index][type] = value;
    }
    else if (type === "ca") {
        new_pillar[index][type] = value;
    }
    else if (type === "user" || type === "pass") {
        new_pillar[index].test[type] = value;
    }
    else if (type === "delete") {
        new_pillar.splice(index, 1);
    }
    else if (type === "add") {
        new_pillar.push({
            credentials: "",
            groupQuery: "(objectclass=group)",
            groupSearchBase: "",
            groupType: "group",
            personQuery: "(&(objectclass=user)(userAccountControl:1.2.840.113556.1.4.803:=512))",
            personType: "user",
            principal: "",
            url: "",
            ca: "",
            userIdAttributeName: "sAMAccountName",
            userNameFormat: "",
            userSearchBase: "",
            test: {
                user: "",
                pass: ""
            }
        })
    }

    return new_pillar
}

//Handles change for the ical Pillar  
export const icalPillarHelper = (type, value, index, pillar) => {
    let new_pillar = pillar

    if (type === "url" || type === "has_terms") {
        new_pillar.ical_url[index][type] = value
    }
    else if (type === "delete") {
        new_pillar.ical_url.splice(index, 1);
    }
    else if (type === "add") {
        new_pillar.ical_url.push({
            url: "",
            has_terms: false
        })
    }
    else if (type === "eventAdd") {
        if (new_pillar.events === undefined || new_pillar.events === null) {
            new_pillar.events = [{
                cal_event: "",
                start_date: "",
                start_time: "",
                end_date: "",
                end_time: "",
            }];
        } else {
            new_pillar.events = [...value]
        }

    }

    else if (type === "eventDelete") {
        new_pillar.events.splice(index, 1);
    }

    else if (type === "eventClear") {
        new_pillar.events = [];
    }

    else if (type === "eventFresh") {
        delete new_pillar.events;
    }

    return new_pillar
}


//Handles change for the sslproxy Pillar  (SSO, SSL and inactivity)
export const SSLproxyPillarHelper = (value, pillar) => {

    let new_pillar = pillar

    Object.keys(value).forEach(k => {
        if (k === "hostname" || k === "password" || k === "sso_type" ||
            k === "krb_realm" || k === "krb_kdc" || k === "krb_keytab" ||
            k === "oidc_metadata_url" || k === "oidc_client_id" || k === "oidc_secret" ||
            k === "saml_metadata_url" || k === "saml_cert" || k === "saml_login_url" || k === "saml_uid_name" || k === "saml_group_name") {
            new_pillar[k] = value[k];

            if (k === "sso_type") {
                new_pillar.krb_realm = "";
                new_pillar.krb_kdc = "";
                new_pillar.krb_keytab = "";
                new_pillar.oidc_metadata_url = "";
                new_pillar.oidc_client_id = "";
                new_pillar.oidc_secret = "";
                new_pillar.saml_metadata_url = "";
                new_pillar.saml_cert = "";
                new_pillar.saml_uid_name = "";
                new_pillar.saml_login_url = "";
                new_pillar.saml_group_name = "";

            }
        }
        else if (k === "enable_timeout" || k === "action_timeout" || k === "popup_timeout") {
            new_pillar[k] = value[k].toString();
        }
    })

    return new_pillar
}

//Handles change for the acls Pillar  
export const AclsPillarHelper = (index, type, value, pillar, index2) => {

    let new_pillar = pillar

    if (type === "actions" || type === "outcome") {
        new_pillar.acls[index][type] = value;
    }
    else if (type === "action" || type === "user" || type === "node_id") {
        new_pillar.test[type] = value;
    }
    else if (type === "delete") {
        new_pillar.acls.splice(index, 1);
    }
    else if (type === "add") {
        new_pillar.acls.push({
            roles: [""],
            actions: [""],
            params: null,
            outcome: "ALLOW"
        })
    }
    else if (type === "roles") {
        if (value === "add") {
            new_pillar.acls[index].roles.push("");
        }
        else if (value === "delete") {
            new_pillar.acls[index].roles.splice(index2, 1);
        }
        else {
            new_pillar.acls[index].roles[index2] = value;
        }
    }

    return new_pillar
}

//Handles change for the sis/integrations Pillar  
export const SisPillarHelper = (value, pillar) => {

    let new_pillar = pillar
    Object.keys(value).forEach(k => {
        if (k === "type" || k === "host" || k === "db" || k === "username" ||
            k === "password" || k === "host" || k === "instance" ||
            k === "photo_folder" || k === "token" || k === "company_code" || k === "csv" ||
            k === "app_code" || k === "tenant" || k === "client_id" || k === "include_inactive" || k === "include_staff" || k === "company_code_staff" || k === "app_code_staff" || k === "token_staff" || k === "host_staff") {

            new_pillar[k] = value[k];

            //to setup 'default read from custom' when changing type. 
            if (new_pillar[k] === 'custom' && pillar[k] === "") {
                new_pillar.read_from_custom_yml = '~'
            }

            if (new_pillar["include_staff"] === "true") {
                if (k === "company_code_staff")
                    new_pillar.company_code_staff = value[k];
                if (k === "app_code_staff")
                    new_pillar.app_code_staff = value[k];
                if (k === "host_staff")
                    new_pillar.host_staff = value[k];
                if (k === "token_staff")
                    new_pillar.token_staff = value[k];
            }
            if (k === "type") {
                new_pillar.instance = "";
                new_pillar.include_inactive = "";
                new_pillar.include_staff = "";
                new_pillar.company_code = "";
                new_pillar.company_code_staff = "";
                new_pillar.app_code = "";
                new_pillar.token = "";
                new_pillar.tenant = "";
                new_pillar.client_id = "";
                new_pillar.db = "";
                new_pillar.photo_folder = "";
                new_pillar.host = "";
                new_pillar.username = "";
                new_pillar.password = "";
                new_pillar.opt_type = "";
                new_pillar.csv = "";
                if (value[k] === "synergetic" || value[k] === "simon" || value[k] === "maze") {
                    new_pillar.read_from_custom_yml = "false";
                }
                else if (value[k] === "custom") {
                    new_pillar.read_from_custom_yml = "~";
                }
                else if (value[k] === "schoolbench") {
                    new_pillar.host = "pseudo";
                    new_pillar.username = "pseudo";
                    new_pillar.password = "pseudo";
                    new_pillar.db = "pseudo";
                    new_pillar.photo_folder = "/mnt/profiles";
                    new_pillar.opt_type = "test";
                    new_pillar.csv = "/opt/sbsms/template.csv";
                    new_pillar.read_from_custom_yml = "true";
                }
                else if (value[k] === "tass" || value[k] === "sentral" || value[k] === "edumate" || value[k] === "wonde") {
                    new_pillar.include_inactive = "false";
                    new_pillar.include_staff = "false";
                    new_pillar.username = "pseudo";
                    new_pillar.password = "pseudo";
                    new_pillar.db = "pseudo";
                    new_pillar.opt_type = "test";
                    new_pillar.read_from_custom_yml = "false";
                }
            }

        }
    })

    return new_pillar
}


//Handles change for the vpn 
export const vpnPillarHelper = (value, pillar) => {

    let new_pillar = pillar

    Object.keys(value).forEach(k => {
        new_pillar[k] = value[k];
    })

    return new_pillar
}


//Handles change for the autoupdates 
export const autoupdatesPillarHelper = (value, pillar) => {

    let new_pillar = pillar

    Object.keys(value).forEach(k => {
        if (k === "autoupdates") {
            new_pillar = value[k];
        }
    })

    return new_pillar
}

//Handles change for the watermark 
export const WatermarkPillarHelper = (value, pillar) => {

    let new_pillar = pillar

    Object.keys(value).forEach(k => {
        if (k === "watermark") {
            new_pillar = value;
        }
    })

    return new_pillar
}

//Handles change for token 
export const tokenPillarHelper = (value, pillar) => {

    let new_pillar = pillar

    Object.keys(value).forEach(k => {
        new_pillar[k] = value[k];
    })

    return new_pillar
}

//Handles removing onlineuser 
export const OnlineuserHelper = async (payload) => {

    let headers = { 'Content-Type': 'application/json' }
    headers["Semabench-Token"] = token;

    return await fetch("../admin/api/v1/system/listupdate", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload)
    })
        .then(resp => resp.json())
        .then(result => {
            return <></>

        })
        .catch(error => {
            return <></>
        })

}


// Appends "0" to the front of the number
function pad(n) {
    return (n < 10) ? '0' + n.toString() : n.toString();
}

const testPillarEle = (result) => {
    let returnEle = <></>
    let fail = false;

    if (result !== undefined) {
        let count = 0
        returnEle = <>
            <h1>Results:</h1>
            <div className="row">
                <div className="col-12 mb-5">
                    <div className="separator mb-5"></div>
                </div>
            </div>

            {result.map(item => {
                // Count upwards
                count++

                let err = <></>
                if (item.error !== undefined && item.error !== null && item.error !== "") {
                    // Only displays Error element if there is an error on ldaps
                    if (item.error.includes("Can't contact LDAP server")) {
                        item.output = "Can't contact LDAP server"
                    } else if (item.error.includes("Invalid credentials")) {
                        item.output = "Wrong Credentials"
                    } else {
                        if (item.name.includes("LDAP")) {
                            if (item.name.includes("Test Login") || item.name.includes("Test User Search")) {
                                item.output = "Cannot find Test User"
                            } else if (item.name.includes("Login")) {
                                item.output = "Check your LDAP URL, Principal and Password"
                            } else if (item.name.includes("Certificate Verification")) {
                                item.output = "Cannot connect LDAP server with the Certificate"
                            } else if (item.name.includes("Certificate Orders")) {
                                item.output = "LDAPs Certificate need to be in this order: (ROOT-CA/CA), (ROOT-CA/CA/others), (CA/others), Single ROOT-CA or Single CA"
                            } else if (item.name.includes("Group Search")) {
                                item.output = "Cannot find LDAP Groups"
                            } else if (item.name.includes("Group Class")) {
                                item.output = "Cannot find LDAP Groups using Group Class"
                            } else if (item.name.includes("User Search")) {
                                item.output = "Cannot find LDAP Users"
                            } else if (item.name.includes("User Class")) {
                                item.output = "Cannot find LDAP Users using User Class"
                            }
                        }
                    }

                    err = <p className="setrequired">Error: {item.error}</p>
                }

                let icon = <LegacyCheckIcon color='green' />;
                if (item.pass === false) {
                    icon = <LegacyCloseIcon color='red' />;
                    fail = true;
                }

                return <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-11 offset-lg-1 mb-5">
                                <div className="mb-5">
                                    <h2 className="mb-2 d-flex align-items-center">
                                        {icon} &nbsp; &nbsp; <span className="numberCircle">{pad(count)}</span> {item.name}
                                    </h2>
                                    <div className="separator"></div>
                                </div>
                                <div className="mb-50">
                                    <p>{item.description}</p>
                                    <p>Pass: <b>{item.pass.toString()}</b></p>
                                    {item.pass ? item.output !== "" && <p>Outcome: {item.output}</p> : item.output !== "" && <p>Outcome: <b style={{ color: "red" }}>{item.output}</b></p>}
                                    {err}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </>
    }

    return { returnEle, fail };
}

//New fuctions to handle acl testing results
const testAclsEle = (result) => {
    let returnEle = <></>
    let fail = false;

    if (result !== undefined) {
        returnEle = <>
            <h1>Access List Test Results</h1>
            <div className="row">
                <div className="col-12 mb-5">
                    <div className="separator mb-5"></div>
                </div>
            </div>

            <div>
                <h4 className="mb-5">Roles</h4>
                <ul>{result.roles.map((role, i) => <li key={"user_role" + i}>{role}</li>)}</ul>
            </div>

            <p className="mb-2 d-flex align-items-center"><strong>Action</strong>: {result.test.action}</p>
            <p className="mb-2 d-flex align-items-center"><strong>Outcome</strong>: {result.outcome ? "Allow" : "Deny"}</p>

        </>
    }

    return { returnEle, fail };
}


//to load subscription detail
export const loadSubscription = async (section, payload) => {
    let headers = payload instanceof FormData ? {} : { 'Content-Type': 'application/json' }

    headers["Semabench-Token"] = token;

    let url = "../admin/api/v1/subscription"

    return await fetch(url, {
        method: "GET",
        headers: headers,
        token: JSON.stringify(payload)
    }).then(resp => {
        //redirect the users to the login pages if the login token is invalid
        if (resp.status === 401) {
            Check_login_ticket_admin('Token', 'validate')
        }
        return resp.json()
    })
        .then(result => {
            let responseEle = testtokenEle(result);
            return responseEle
        })
        .catch(result => {
            let responseEle = testtokenEle(result);
            return responseEle
        })

}

//to load subscription detail
export const loadPortalSubscription = async (section, payload) => {
    let headers = payload instanceof FormData ? {} : { 'Content-Type': 'application/json' }

    headers["Semabench-Token"] = token;

    let url = "../admin/api/v1/portal_subscription"

    return await fetch(url, {
        method: "GET",
        headers: headers,
        token: JSON.stringify(payload)
    }).then(resp => {
        //redirect the users to the login pages if the login token is invalid
        if (resp.status === 401) {
            Check_login_ticket_admin('Portal Token', 'validate')
        }
        return resp.json()
    })
        .then(result => {
            let responseEle = testtokenEle(result);
            return responseEle
        })
        .catch(result => {
            let responseEle = testtokenEle(result);
            return responseEle
        })

}

//to display subscription status
export const DisplaytokenEle = (result) => {
    let returnEle = <></>

    // Checks that the results are not null
    if (result != null) {
        // Checks that the result returns and invalid token
        if (result.not_valid !== undefined) {
            // if Invalid Token returns blank
            if (result.not_valid) {
                returnEle = <></>
            }
        } else {
            // If its not invalid, returns the Expiry Date of the token
            let year = result.end_date.substring(0, 4)
            let month = result.end_date.substring(5, 7)
            let day = result.end_date.substring(8, 10)

            if (result !== undefined) {
                returnEle = <>
                    <p className="mb-2"><strong>Expiry Date</strong>: {day}-{month}-{year}</p>
                </>
            }
        }


    }

    return returnEle;
}


//handle the token element to diplay 
const testtokenEle = (result) => {
    let returnEle = <></>

    if (result !== undefined && result.email !== undefined) {
        let year = result.end_date.substring(0, 4)
        let month = result.end_date.substring(5, 7)
        let day = result.end_date.substring(8, 10)

        returnEle = <>
            <h1>Subscription Validated</h1>
            <p className="mb-2"><strong>Expiry Date</strong>: {day}-{month}-{year}</p>
        </>
    }
    else {
        returnEle = <>
            <h1>Failed to Validate Subscription</h1>
            <p className="mb-2">Failed to validate the subscription, please check your token and try again. </p>
        </>
    }

    return { returnEle, result };
}


//to load media consent data
export const loadMediaConsent = async (section, payload) => {
    let headers = payload instanceof FormData ? {} : { 'Content-Type': 'application/json' }

    headers["Semabench-Token"] = token;

    let url = '../api/v1/repo/s/semabench/query?json.nl=map&facet=on&facet.field=sb_parentPermissions&q=sb_parentPermissions:*'

    let media_consent = [];

    return await fetch(url, {
        method: "POST",
        headers: headers
    })
        .then(resp => resp.json())
        .then(result => {

            let resp = result.facet_counts.facet_fields.sb_parentPermissions;

            if (resp !== "" && resp !== undefined) {

                Object.keys(resp).forEach(consent => {
                    if (consent === "" || consent === " ") {
                        media_consent.push({
                            field_name: "Absent Field",
                            value: []
                        })
                    }
                    else {
                        media_consent.push({
                            field_name: consent,
                            value: []
                        })
                    }

                })

            }

            return media_consent
        })

}

// response and auto logout
export const getUpgrade = (retry) => {
    // Created an object that will be returned
    let obj = {}
    obj.save = true;
    obj.msg = <></>
    obj.retry = retry;

    // Calls the api to get the status update
    return fetch("../api/v1/version/upgrade/status", {
        method: "GET",
        headers: { "Semabench-Token": token }
    }).then(res => res.json())
        .then(response => {

            // If the status has changes, then stop saying its in saving mode
            obj.save = response.running;

            // if the status is not running its workout what has passed and what has failed
            if (response.running !== true) {
                try {
                    // let changelog = response.stdout
                    // changelog = changelog.split(/(?:\n)/g)

                    // Splits each test by a new line and 5x -
                    let t = response.stdout.split("\n----------")
                    let array = []
                    let success = "";
                    let fail = ""
                    let totalTime = "";
                    let statesRun = "";
                    let hasFail = false
                    t.forEach(val => {
                        let r = val.trim();
                        r.replace("\n", "");
                        // Replaces all new lines and formats
                        if (!r.includes("local:")) {
                            let splitValues = r.split('\n');
                            let obj = {};
                            // Creates an object of the summary and any failed items
                            splitValues.forEach(split => {
                                if (split !== "") {
                                    let keys = split.split(":");
                                    obj[keys[0].trim()] = keys[1];

                                    if (keys[1] === undefined) {
                                        keys[1] = "";
                                    }

                                    if (keys[0].trim() === "Succeeded") {
                                        success = keys[1]
                                    }

                                    if (keys[0].trim() === "Failed") {
                                        fail = keys[1]
                                        if (fail !== 0) {
                                            hasFail = true;
                                        }

                                    }

                                    if (keys[0].trim() === "Total states run") {
                                        statesRun = keys[1]
                                    }

                                    if (keys[0].trim() === "Total run time") {
                                        totalTime = keys[1]
                                    }
                                }
                            })
                            array.push(obj);
                        }
                    })

                    let failEle = <></>
                    if (hasFail) {
                        // If any fails then adds it
                        let count = 0
                        failEle = <>
                            <div className="mb-5">
                                <h2 className="mb-2 d-flex align-items-center">
                                    Fail States:
                                </h2>
                                <div className="separator"></div>
                            </div>
                            {
                                // Loops over the array and sees if any are false (Failed)
                                // If so then will add the Name and response of that failed item highlighted in red
                                array.map((item, key) => {
                                    if (item.Result !== undefined) {
                                        if (item.Result.trim() === "False") {
                                            count++;
                                            return <div className='mb-3'>
                                                <h3 className='mb-3'>Fail {count}:</h3>
                                                {Object.keys(item).map(val => {
                                                    return <p className="setrequired">{val} : {item[val]}</p>
                                                })}
                                            </div>

                                        }
                                    }
                                    return <></>
                                })
                            }
                        </>
                    }

                    // Output message of the response from the server once compelted
                    // This is a summary of the results, the run time. Total States and total time
                    obj.msg = <><h1>Output:</h1>
                        <div className="row">
                            <div className="col-12 mb-5">
                                <div className="separator mb-5"></div>
                            </div>
                            <div className="container-fluid">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-11 offset-lg-1 mb-5">
                                            <div className="mb-5">
                                                <h2 className="mb-2 d-flex align-items-center">
                                                    Summary:
                                                </h2>
                                                <div className="separator"></div>
                                            </div>
                                            <div className="mb-5">
                                                <p>Success: {success}</p>
                                                <p>Failed: {fail}</p>
                                                <p>Total states run: {statesRun}</p>
                                                <p>Total Time: {totalTime}</p>
                                            </div>
                                            {failEle}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                    return obj;
                } catch (err) {
                    let r = retry + 1;
                    obj.retry = r
                    return obj
                }


            } else {
                let r = retry + 1;
                obj.retry = r
                return obj
            }
        })
        .catch(error => {

            obj.save = false
            return obj
        })
}

export const getSaveData = (retry) => {
    // Created an object that will be returned
    let obj = {}
    obj.save = true;
    obj.msg = <></>
    obj.retry = retry;

    // Calls the api to get the status update
    return fetch("../admin/api/v1/version/update/status", {
        method: "GET",
        headers: { "Semabench-Token": MASTER_TOKEN }
    }).then(res => res.json())
        .then(response => {

            // If the status has changes, then stop saying its in saving mode
            obj.save = response.running;

            // if the status is not running its workout what has passed and what has failed
            if (response.running !== true) {
                try {
                    loadPillar();
                    loadConfig();
                    // let changelog = response.stdout
                    // changelog = changelog.split(/(?:\n)/g)

                    // Splits each test by a new line and 5x -
                    let t = response.stdout.split("\n----------")
                    let array = []
                    let success = "";
                    let fail = ""
                    let totalTime = "";
                    let statesRun = "";
                    let hasFail = false
                    t.forEach(val => {
                        let r = val.trim();
                        r.replace("\n", "");
                        // Replaces all new lines and formats
                        if (!r.includes("local:")) {
                            let splitValues = r.split('\n');
                            let obj = {};
                            // Creates an object of the summary and any failed items
                            splitValues.forEach(split => {
                                if (split !== "") {
                                    let keys = split.split(":");
                                    obj[keys[0].trim()] = keys[1];

                                    if (keys[1] === undefined) {
                                        keys[1] = "";
                                    }

                                    if (keys[0].trim() === "Succeeded") {
                                        success = keys[1]
                                    }

                                    if (keys[0].trim() === "Failed") {
                                        fail = keys[1]
                                        if (fail !== 0) {
                                            hasFail = true;
                                        }

                                    }

                                    if (keys[0].trim() === "Total states run") {
                                        statesRun = keys[1]
                                    }

                                    if (keys[0].trim() === "Total run time") {
                                        totalTime = keys[1]
                                    }
                                }
                            })
                            array.push(obj);
                        }
                    })

                    let failEle = <></>
                    if (hasFail) {
                        // If any fails then adds it
                        let count = 0
                        failEle = <>
                            <div className="mb-5">
                                <h2 className="mb-2 d-flex align-items-center">
                                    Fail States:
                                </h2>
                                <div className="separator"></div>
                            </div>
                            {
                                // Loops over the array and sees if any are false (Failed)
                                // If so then will add the Name and response of that failed item highlighted in red
                                array.map((item, key) => {
                                    if (item.Result !== undefined) {
                                        if (item.Result.trim() === "False") {
                                            count++;
                                            return <div className='mb-3'>
                                                <h3 className='mb-3'>Fail {count}:</h3>
                                                {Object.keys(item).map(val => {
                                                    return <p className="setrequired">{val} : {item[val]}</p>
                                                })}
                                            </div>

                                        }
                                    }
                                    return <></>
                                })
                            }
                        </>
                    }

                    // Output message of the response from the server once compelted
                    // This is a summary of the results, the run time. Total States and total time
                    obj.msg = <><h1>Output:</h1>
                        <div className="row">
                            <div className="col-12 mb-5">
                                <div className="separator mb-5"></div>
                            </div>
                            <div className="container-fluid">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-11 offset-lg-1 mb-5">
                                            <div className="mb-5">
                                                <h2 className="mb-2 d-flex align-items-center">
                                                    Summary:
                                                </h2>
                                                <div className="separator"></div>
                                            </div>
                                            <div className="mb-5">
                                                <p>Success: {success}</p>
                                                <p>Failed: {fail}</p>
                                                <p>Total states run: {statesRun}</p>
                                                <p>Total Time: {totalTime}</p>
                                            </div>
                                            {failEle}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                    return obj;
                } catch (err) {
                    let r = retry + 1;
                    obj.retry = r
                    return obj
                }


            } else {
                let r = retry + 1;
                obj.retry = r
                return obj
            }
        })
        .catch(error => {

            obj.save = false
            return obj
        })
} 
