import { toaster, Message } from 'rsuite';
import { Check_login_ticket, decrypt_storage_values } from './helper';

export var MyBin = [];

// Initail state
export const initialState = {
    trashcan: new Set()
}

export var trashcan = initialState;

// Method to call the back and loads the trashcan database.
export async function cleanTrashcan() {
    let token = localStorage.getItem("T");
    if (token !== undefined && token !== null) {
      token = decrypt_storage_values(token);
    }
    let header = {
        'Semabench-Token': token
    }

    return await fetch("../../api/v1/user/trashcan/delete", {
        headers: header
    }).then(resp => {
        //redirect the users to the login pages if the login token is invalid
        if (resp.status === 401) {
            Check_login_ticket()
        }
        return resp.json()
    })
        .then(result => {
            if (result.length > 0) {
                deleteNodes(result)
            }
            // return result;
        })
}

// Method to call the back and loads the trashcan database.
export async function loadTrashcan() {
    let token = localStorage.getItem("T");
    if (token !== undefined && token !== null) {
      token = decrypt_storage_values(token);
    }
    let header = {
        'Semabench-Token': token
    }

    return await fetch("../../api/v1/user/trashcan", {
        headers: header
    }).then(resp => resp.json())
        .then(result => {
            MyBin = result
            return result;
        })
}

// Used to upload the UUIDs that need to be added to the trashcan
export function updateTrashcan(trashcan) {
    let token = localStorage.getItem("T");
    if (token !== undefined && token !== null) {
      token = decrypt_storage_values(token);
    }
    let header = {
        'Semabench-Token': token
    }

    let currentTrash = MyBin;

    let newTrash = [...currentTrash, ...trashcan];

    MyBin = newTrash

    fetch("../../api/v1/user/trashcan", {
        headers: header,
        method: "POST",
        body: JSON.stringify(trashcan)
    }).then(resp => resp.json())
        .then(result => {
            newTrash = result
        })

    return newTrash;
}

// Removed the UUID from the trashcan database, hence restoring it to Gallery
export async function restoreTrashcan(nodes) {
    let token = localStorage.getItem("T");
    if (token !== undefined && token !== null) {
      token = decrypt_storage_values(token);
    }

    let header = {
        'Semabench-Token': token,
        'Content-Type': 'application/json'
    }

    toaster.clear();
    toaster.push(
        <Message type="info" showIcon closable>Restoring Items...</Message>
    );

    // APi to remove from the trashcan 
    fetch("../api/v1/user/trashcan", {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(nodes)
    }).then(resp => {
        return resp;
    })
        .catch((error) => {
            return;
        })

    if (nodes.length > 1) {
        toaster.push(
            <Message type="success" showIcon closable>All Items Restored</Message>
        );
    } else {
        toaster.clear();
        toaster.push(
            <Message type="success" showIcon closable>Item Restored</Message>
        );
    }
    return true;
}

// Deletes the Node form alfresco and from the trashcan database.
export async function deleteNodes(nodes) {
    let token = localStorage.getItem("T");
    if (token !== undefined && token !== null) {
      token = decrypt_storage_values(token);
    }

    let new_nodes = []
    if (nodes?.length > 0) {
        nodes.forEach(node => {
            new_nodes.push({ "id": node, "storeRef": { "protocol": "workspace", "identifier": "SpacesStore" } })
        });
    }

    let payload = {
        nodes: new_nodes
    }

    let header = {
        'Semabench-Token': token,
        'Content-Type': 'application/json; charset=utf-8'
    }

    // Api to delete the item completly from schoolbench
    await fetch("../api/v1/repo/s/semabench/items", {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(payload)
    }).then(async resp => {
        // If it deleteded the item, then removes that item from the trashcan
        if (resp.ok) {
            // await fetch("../api/v1/user/trashcan", {
            //     method: 'DELETE',
            //     headers: header,
            //     body: JSON.stringify(nodes)
            // }).then(resp => {
            //     // 
            // })
            //     .catch((error) => {
            //         // Alert.error('Deleting Item Failed');
            //         toaster.push(
            //             <Message type="error" showIcon closable>Deleting Item Failed</Message>,

            //         );
            //     })
        } else {
            toaster.clear();
            toaster.push(
                <Message type="error" showIcon closable>{resp.status + " " + resp.statusText}</Message>,

            );
        }


    })
        .catch((error) => {

        })

    toaster.clear();
    return true;
}

// method for directly removing the node
export async function deleteNode(node) {
    let token = localStorage.getItem("T");
    if (token !== undefined && token !== null) {
      token = decrypt_storage_values(token);
    }

    let payload = {
        nodes: [{ "id": node, "storeRef": { "protocol": "workspace", "identifier": "SpacesStore" } }]
    }

    let header = {
        'Semabench-Token': token,
        'Content-Type': 'application/json'
    }

    toaster.push(
        <Message type="info" showIcon closable>Deleting Items...</Message>,

    );

    // Api to delete the item completly from schoolbench
    return await fetch("../api/v1/repo/s/semabench/items", {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(payload)
    }).then(resp => {
        toaster.clear();
        toaster.push(
            <Message type="success" showIcon closable>Item Deleted</Message>
        );
    })
        .catch((error) => {

        })

}
