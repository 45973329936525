import { toaster, Message } from 'rsuite';
import { Buffer } from 'buffer';
import { WebSocketSetup } from '../../views/socket';
import { checkAcl } from '../../adminressources/ACLHelper.js';
import { decrypt_storage_values, encrypt_storage_values } from './helper';
import { config as getConfig } from './Config.js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export var MultiCampus = null;

// Method for when a user submits a password
export async function submit_login(username, pass) {
    // Checks to make user user attempted to add a password
    if (pass === "" || pass === undefined || pass == null) {
        toaster.clear();
        toaster.push(<Message type='error' showIcon closable>Please enter a valid password</Message>,
        {duration: 5000}
        );
        return null;

    } else {
        // Encrypts the pass to base64
        var password = Buffer.from(pass).toString('base64');

        // Sends the request and awaits for the response to see if username and password is valid.
        // sets the source as media so that users can only be logged in at one location per source.
        const response = await fetch("../api/v1/repo/s/semabench/ticket", {
            method: "POST",
            mode: 'no-cors',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Source": 'media'
            },
            body: JSON.stringify({ name: username, value: password, source: 'media' })
        })
        if (response.status === 401) {
            toaster.clear();
            toaster.push(<Message type='error'>You do not have View permissions, please contact your IT support</Message>,
            {duration: 5000}
            );
        }

        return response.json();
    }
}

// Checkls if there is a token or the user can login on fist start
export async function check_login() {

    let token = localStorage.getItem("T");
    if (token !== undefined && token !== null) {
        token = decrypt_storage_values(token);
    }

    let headers = {};

    if (token !== undefined || token !== null) {
        headers["Semabench-Token"] = token;
    }

    if (document.URL.match("#token")) {
        token = document.URL.split("#token:")[1];
    }


    if (token === undefined || token === null) {
        token = "NO TOKEN FOUND IN HEADER OR BODY"
    }

    return await fetch("../api/v1/repo/s/semabench/ticket", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Semabench-Token': token,
            'source': 'media'
        }
    }).then(res => {
        let url = res.headers.get("location");
        if (url != null && (token === undefined || token === null || token === "NO TOKEN FOUND IN HEADER OR BODY")) {
            window.location.replace(url);
        } else {
            try {
                return res.json()
            } catch (e) {
                return e
            }
        }

    })
        .then(response => {
            return response;
        })
}

export async function check_login_bool() {

    let token = localStorage.getItem("T");
    
    if (token !== undefined && token !== null) {
        token = decrypt_storage_values(token);
    }

    if (document.URL.match("#token")) {
        token = document.URL.split("#token:")[1];
    }

    var req = new XMLHttpRequest();
    req.open('GET', document.location, false);
    req.send(null);

    var head = req.getResponseHeader("Semabench-Token");
    if (head !== undefined && head !== null && head !== "") {
        token = head
    }

    if (document.URL.match("samllogin/#token")) {
        token = document.URL.split("#token:")[1];
    }

    let headers = {};

    if (token !== undefined && token != null) {
        headers["Semabench-Token"] = token;
    }

    if (token === undefined || token == null) {
        token = "NO TOKEN FOUND IN HEADER OR BODY"
    }

    await fetch("../api/v1/repo/s/semabench/ticket", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Semabench-Token': token,
            'source': 'media'
        }
    }).then(res => {
        let url = res.headers.get("location");
        if (url !== null && url !== undefined && (token === undefined || token === null || token === "NO TOKEN FOUND IN HEADER OR BODY")) {
            window.location.replace(url);
        } else {
            if (res !== undefined && res !== null) {
                try {
                    if (res.status !== 401) {
                        return res.json()
                    } else {
                        return false
                    }
                } catch (e) {
                    return false
                }
            } else {
                return false
            }
        }

    })
        .then(async response => {

            if (response.ticket !== undefined && response.ticket !== null) {
                multicampus_checker(response)
                localStorage.setItem("valid", 'Okay');
                let expires = cookies.get('E')
                if (expires === undefined || expires === null) {
                    expires = new Date(new Date().getTime() + (1000 * 60 * 60 * 12))
                } else {
                    expires = JSON.parse(decrypt_storage_values(expires))
                }

                localStorage.setItem("L", encrypt_storage_values(JSON.stringify(response)));
                localStorage.setItem("T", encrypt_storage_values(response.ticket));
                cookies.set('T', encrypt_storage_values(response.ticket), { path: '/', expires: new Date(expires), secure: true, sameSite: "Strict" });
                cookies.set('E', encrypt_storage_values(JSON.stringify(new Date(expires))), { path: '/', expires: new Date(expires), secure: true, sameSite: "Strict" });

                WebSocketSetup()
            }
        })


    return false;

}

//checking multi tanancy when the users login the system.
export function multicampus_checker(login) {

    let config = getConfig;
    if (config == null) {
        return ""
    }

    let login_detail = login;
    //if the user is an admin, skip the below process to identify the campus.
    if (checkAcl(login_detail.roles, config.acls, "ADMIN")) {
        return 'admin'
    } else if (config != null) {
        if (config.multicampus != null) {
            let multicampus = config.multicampus;

            let length = config.multicampus.length;
            if (length > 0) {
                let campus = [];
                //for loop and check role is there, then push the campus name into the campus array, then setup localstorage using that.
                for (let x = 0; x < length; x++) {
                    let roles_len = multicampus[x].roles.length;
                    for (let y = 0; y < roles_len; y++) {
                        if (login_detail.roles.includes(multicampus[x].roles[y])) {
                            campus = [...campus, ...multicampus[x].campus]
                            break;
                        }
                    }
                }
                campus = [...new Set(campus)]; // remove duplicated ones
                MultiCampus = campus
                return campus
            }
        } else {
            return ""
        }
    } else {
        return ""
    }
}
